<template>
	<div>
		<div class="sidebar">
			<div class="logo">
				<img class="small" src="../../assets/teleradio-sm.png" alt="">
				<img class="full" src="../../assets/teleradio.png" alt="">
			</div>
			<div class="snav-container">
				<div class="snav">
					<div class="title">Menu</div>
					<ul>
						<li>
							<router-link to="/admin/dashboard">
								<i class="fa fa-dashboard"></i>
								<span>Dashboard</span>
							</router-link>
						</li>
						<li v-if="isSuper">
							<router-link to="/admin/radiologist">
								<i class="fa fa-user-md"></i>
								<span class="text">Radiologist</span>
							</router-link>
						</li>
						<li v-if="isSuper">
							<router-link to="/admin/cardiologist">
								<i class="fa fa-heartbeat"></i>
								<span class="text">Cardiologist</span>
							</router-link>
						</li>
						<li v-if="isSuper">
							<router-link to="/admin/pulmonologist">
								<i class="fa fa-solid fa-lungs"></i>
								<span class="text">Pulmonologist</span>
							</router-link>
						</li>
						<li v-if="isSuper">
							<router-link to="/admin/neurologist">
								<i class="fa fa-solid fa-brain"></i>
								<span class="text">Neurologist</span>
							</router-link>
						</li>
						<li>
							<router-link to="/admin/center">
								<i class="fa fa-hospital-o"></i>
								<span class="text">Center</span>
							</router-link>
						</li>
						<li v-if="isSuper">
							<router-link to="/admin/agent">
								<i class="fa fa-user-o"></i>
								<span class="text">Agents</span>
							</router-link>
						</li>

						<li>
							<router-link to="/admin/reviews">
								<i class="fa fa-eye"></i>
								<span class="text">Reviews</span>
							</router-link>
						</li>

						<li :class="{ open: openDropdown == 'reports' }">
							<a @click="openDropdown = openDropdown == 'reports' ? '' : 'reports'">
								<i class="pull-right fa fa-chevron-left"></i>
								<i class="fa fa-file-excel-o"></i>
								<span>Reports</span>
							</a>
							<ul>
								<li>
									<router-link to="/admin/xray-reporting">
										<i class="fa fa-file-excel-o"></i>
										<span class="text">X-Ray Reporting</span>
									</router-link>
								</li>
								<li>
									<router-link to="/admin/ecg-reporting">
										<i class="fa fa-file-excel-o"></i>
										<span class="text">ECG Reporting</span>
									</router-link>
								</li>
								<li>
									<router-link to="/admin/pft-reporting">
										<i class="fa fa-file-excel-o"></i>
										<span class="text">PFT Reporting</span>
									</router-link>
								</li>
								<li>
									<router-link to="/admin/eeg-reporting">
										<i class="fa fa-file-excel-o"></i>
										<span class="text">EEG Reporting</span>
									</router-link>
								</li>
								<li v-if="isSuper">
									<router-link to="/admin/received">
										<i class="fa fa-file-excel-o"></i>
										<span class="text">Received</span>
									</router-link>
								</li>
								<li v-if="isSuper">
									<router-link to="/admin/all-radiologist-payment">
										<i class="fa fa-file-excel-o"></i>
										<span class="text">Radiologist Pyament</span>
									</router-link>
								</li>
								<li v-if="isSuper">
									<router-link to="/admin/all-cardiologist-payment">
										<i class="fa fa-file-excel-o"></i>
										<span class="text">Cardiologist Pyament</span>
									</router-link>
								</li>
								<li v-if="isSuper">
									<router-link to="/admin/all-pulmonologist-payment">
										<i class="fa fa-file-excel-o"></i>
										<span class="text">Pulmonologist Pyament</span>
									</router-link>
								</li>
								<li v-if="isSuper">
									<router-link to="/admin/all-neurologist-payment">
										<i class="fa fa-file-excel-o"></i>
										<span class="text">Neurologist Pyament</span>
									</router-link>
								</li>
								<li>
									<router-link to="/admin/xray-uploading">
										<i class="fa fa-file-excel-o"></i>
										<span class="text">X-Ray Uploading</span>
									</router-link>
								</li>
								<li>
									<router-link to="/admin/ecg-uploading">
										<i class="fa fa-file-excel-o"></i>
										<span class="text">ECG Uploading</span>
									</router-link>
								</li>
								<li>
									<router-link to="/admin/pft-uploading">
										<i class="fa fa-file-excel-o"></i>
										<span class="text">PFT Uploading</span>
									</router-link>
								</li>
								<li>
									<router-link to="/admin/eeg-uploading">
										<i class="fa fa-file-excel-o"></i>
										<span class="text">EEG Uploading</span>
									</router-link>
								</li>
							</ul>
						</li>
						
						<li v-if="isSuper">
							<router-link to="/admin/transaction-audit">
								<i class="fa fa-money"></i>
								<span class="text">Transaction Audit</span>
							</router-link>
						</li>

						<li>
							<router-link to="/admin/xray-overview">
								<i class="fa fa-solid fa-x-ray"></i>
								<span class="text">X-Ray Overview</span>
							</router-link>
						</li>

						<li v-if="isSuper">
							<router-link to="/admin/payment-overview">
								<i class="fa fa-money"></i>
								<span class="text">Payment Overview</span>
							</router-link>
						</li>
						

						

						<li v-if="isSuper">
							<router-link to="/admin/notice">
								<i class="fa fa-bell"></i>
								<span class="text">Notice</span>
							</router-link>
						</li>
						<li v-if="isSuper">
							<router-link to="/admin/default-rates">
								<i class="fa fa-inr"></i>
								<span class="text">Manage Defaults</span>
							</router-link>
						</li>
						<li>
							<router-link to="/admin/test">
								<i class="fa fa-flask"></i>
								<span class="text">Test</span>
							</router-link>
						</li>
						<li v-if="isSuper">
							<router-link to="/admin/options">
								<i class="fa fa-server"></i>
								<span class="text">Options</span>
							</router-link>
						</li>
						<li>
							<router-link @click.prevent="logOut" to="/">
								<i class="fa fa-lock"></i>
								<span class="text">Logout</span>
							</router-link>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="top-container">
			<header>
				<div class="rnav">
					<div @click="logOut" title="Logout">
						<i class="fa fa-lock"></i>
					</div>
					<div title="Speed check">
						<a href="https://fast.com" target="_balnk">
							<i class="fa fa-signal"></i>
						</a>
					</div>
					<div>
						<router-link to="/admin/reviews" @click="hasReview = false; reviewCount = 0;" style="text-decoration: none;" :class="{'text-danger': hasReview}">
							Reviews <span v-if="reviewCount">({{ reviewCount }})</span>
						</router-link>
					</div>
				</div>
				<div class="tnav">
					<ul class="clearfix">
						<li>
							<router-link to="/admin">Dashboard</router-link>
						</li>
					</ul>
				</div>
			</header>
			<div class="content">
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>
<script>

export default {
	data() {
		return {
			openDropdown: '',
			activeLink: '',
			isSuper: false,
			hasReview: false,
			reviewCount: 0,
		}
	},
	beforeRouteEnter(req, res, next) {
		if (!window.localStorage.admin_access_token) return next("/admin-login");
		next();
	},
	async mounted() {
		window.soc = io(apiOrigin, { transports: ["websocket"] });
		let at = JSON.parse(localStorage.admin_access_token);
		soc.emit('login', {
			type: 'admin',
			id: at.id
		})

		soc.on('review', this.onReview);

		let params = {
			filters: {},
			first: 0,
			rows: 100,
			sortField: 'id',
			sortOrder: -1
		};

		let dt = new Date();
		dt.setDate(dt.getDate()-5);
		
		dt = dt.toISOString();

		params.filters['review_time'] = {
			value: dt,
			matchMode: '>',
		}
		params.filters['status'] = {
			value: 'pending',
			matchMode: '=',
		}


		this.post("admin/load-review_requests", params).then(res => {
			if (res.success) {
				if(res.totalRecords){
					this.hasReview = true;
					this.reviewCount = res.totalRecords;
				}
			}
		});


	},
	unmounted(){
		soc.off('review', this.onReview);
		soc.close();
	},
	methods: {
		onReview(){
			this.hasReview = true;
			this.reviewCount++;
			notiAudio.play();
		},
		enter() {
			let user = JSON.parse(window.localStorage.admin_access_token);
			this.isSuper = user.is_super == 'y';
		},
		logOut() {
			delete window.localStorage.admin_access_token;
			this.$router.push("/admin-login");
		}
	},
}
</script>
<style scoped>
.top-container {
	min-height: 100vh;
	/* background: rgb(235 237 239); */
	background: rgb(247 247 247);
	padding-left: 56px;
}

header {
	background: #fff;
	box-shadow: 0px 4px 8px #ccc;
	border-bottom: 1px solid #ccc;
	height: 56px;

}

.rnav {
	float: right;
	padding-right: 10px;
}

.rnav>div {
	color: #555;
	padding: 15px;
	cursor: pointer;
	font-size: 18px;
	position: relative;
	float: right;
}

.rnav>div:hover {
	color: #333;
}

.rnav>div a {
	color: #555;
}

.tnav {
	float: left;
}

.tnav ul,
.tnav li {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
}

.tnav ul {
	padding-left: 15px;
}

.tnav li {
	float: left;
	padding: 15px;
}

.tnav li a {
	text-decoration: none;
	padding: 5px;
	font-size: 14px;
	color: rgba(0, 0, 21, .5);
	cursor: pointer;
}

.tnav li a:hover,
.tnav li a.router-link-exact-active {
	color: rgba(0, 0, 21, .7);
}

/* width */
.sidebar ::-webkit-scrollbar {
	width: 8px;
	border-radius: 20px;
}

/* button */
.sidebar ::-webkit-scrollbar-button {
	background: #222;
	height: 0;
}

/* Handle */
.sidebar ::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 8px;
}

/* Handle on hover */
.sidebar ::-webkit-scrollbar-thumb:hover {
	background: #aaa;
}

/* Track */
.sidebar ::-webkit-scrollbar-track {
	background: rgba(0, 0, 0, 0);
}

/* The track NOT covered by the handle.*/
.sidebar ::-webkit-scrollbar-track-piece {
	background: rgba(0, 0, 0, 0);
}

/* Corner */
.sidebar ::-webkit-scrollbar-corner {
	background: rgba(0, 0, 0, 0);
}

/* Resizer */
.sidebar ::-webkit-resizer {
	background: rgba(0, 0, 0, 0);
}

.sidebar {
	width: 56px;
	height: 100vh;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 5;
	overflow: hidden;
	/* background: rgb(60 75 100); */
	background: rgb(236 237 240);
	transition: width .3s;
	font-size: .875rem;
	border-right: 1px solid #ddd;
	box-shadow: 1px 0 14px #ccc;
}

.sidebar:hover {
	width: 256px;
}

.snav-container {
	height: 100%;
	padding-bottom: 56px;
}

.sidebar .snav {
	overflow-y: hidden;
}

.sidebar:hover .snav {
	height: 100%;
	overflow-y: overlay;
}

.sidebar .snav ul,
.sidebar .snav li {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
}

.sidebar .snav li {
	overflow: hidden;
	font-size: .875rem;
	font-weight: 400;
}


.sidebar .snav li a {
	overflow: hidden;
	/* color: rgba(255,255,255,.8); */
	color: #555;
	height: 56px;
	min-width: 256px;
	padding: 18px;
	padding-right: 10px;
	transition: background .3s, color .3s;
	text-decoration: none;
	display: block;
	cursor: pointer;
}

.sidebar .snav li a:hover,
.sidebar .snav li a.router-link-exact-active {
	color: #fff;
	/* background: #321fdb; */
	background: rgb(239 151 26);
}

.sidebar .snav li a * {
	visibility: hidden;
}

.sidebar .snav li a .fa {
	font-size: 17px;
	margin-right: 15px;
	visibility: visible;
}

.sidebar .snav li a .fa-chevron-left {
	font-size: 12px;
	margin-top: 5px;
	transition: transform .3s;
}

.sidebar:hover .snav li a * {
	visibility: visible;
}

.sidebar .snav>ul>li>ul {
	max-height: 0;
	overflow: hidden;
	transition: max-height .3s;
}

.sidebar:hover .snav>ul>li.open>ul {
	max-height: 900px;
}

.sidebar:hover .snav>ul>li.open>a .fa-chevron-left {
	transform: rotate(-90deg);
}

.sidebar .snav>ul>li>ul a {
	padding-left: 30px;
	/* background: rgb(81 98 126); */
	background: rgb(206 206 206);
}


.sidebar .title {
	text-transform: uppercase;
	color: rgba(255, 255, 255, .6);
	color: #000;
	height: 0px;
	overflow: hidden;
	transition: .3s;
}

.sidebar:hover .title {
	height: auto;
	padding: .75rem 1rem;
	margin-top: 1rem;
	font-size: 80%;
	font-weight: 700;
}

.logo {
	/* background: rgb(236 237 240); */
	background: rgb(206 206 206);
	height: 56px;
	position: relative;
	padding: 8px;
}

.logo img {
	display: block;
	max-height: 100%;
	max-width: 100%;
	margin: auto;
}

.logo .small {
	display: block;
}

.logo .full {
	display: none;
}

.sidebar:hover .logo .full {
	display: block;
}

.sidebar:hover .logo .small {
	display: none;
}
</style>